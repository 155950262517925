/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'dGQhLcUqdGPjB1fQMXLBXT.png': { uri: '/images/dGQhLcUqdGPjB1fQMXLBXT.png' },
'puJEmZZNDCtCRhxk72YJPZ.png': { uri: '/images/puJEmZZNDCtCRhxk72YJPZ.png' },
'1nC21EQB4Kjc91taAgcSSG.png': { uri: '/images/1nC21EQB4Kjc91taAgcSSG.png' },
'23LrFASS3boirzoeFY6PkQ.png': { uri: '/images/23LrFASS3boirzoeFY6PkQ.png' },
'5DUCZTS4hdoBc34m5h5cDD.png': { uri: '/images/5DUCZTS4hdoBc34m5h5cDD.png' },
'ghftq3w5g6fneVTuUoexE4.png': { uri: '/images/ghftq3w5g6fneVTuUoexE4.png' },
'dJGor5a97p7t7kBENgW7A4.png': { uri: '/images/dJGor5a97p7t7kBENgW7A4.png' },
'uX9H6552mjYUG7nQTR2m8v.png': { uri: '/images/uX9H6552mjYUG7nQTR2m8v.png' },
'mkuYxkmy716Wig4DfNARki.png': { uri: '/images/mkuYxkmy716Wig4DfNARki.png' },
'bpPvmbUhxtjpjYTGbf2us7.png': { uri: '/images/bpPvmbUhxtjpjYTGbf2us7.png' },
's6RSbRdbX1bNpiPh8abeUb.png': { uri: '/images/s6RSbRdbX1bNpiPh8abeUb.png' },
'mgPe3UNzwBSrBKvVjKd4U4.png': { uri: '/images/mgPe3UNzwBSrBKvVjKd4U4.png' },
'aVX8QmfioJavNph96FfLKx.png': { uri: '/images/aVX8QmfioJavNph96FfLKx.png' },
'g7q15XTSirATRfZma8DZZj.png': { uri: '/images/g7q15XTSirATRfZma8DZZj.png' },
'4xpiaYcUn3zf2Qv7L85arw.png': { uri: '/images/4xpiaYcUn3zf2Qv7L85arw.png' },
'mU5odaQFyeixHVimz163wn.png': { uri: '/images/mU5odaQFyeixHVimz163wn.png' },
'pViahm3TCxLS2PVKziRQaQ.png': { uri: '/images/pViahm3TCxLS2PVKziRQaQ.png' },
'qCPfxmTbaXNEb5kqB9JCGN.png': { uri: '/images/qCPfxmTbaXNEb5kqB9JCGN.png' },
'snKgbeEWwjGDySZqyJYLPw.png': { uri: '/images/snKgbeEWwjGDySZqyJYLPw.png' },
'78SM8rAddAJL2pGKMkoi8H.png': { uri: '/images/78SM8rAddAJL2pGKMkoi8H.png' },
'3hdbKVSmnqgbLj911xPgG7.png': { uri: '/images/3hdbKVSmnqgbLj911xPgG7.png' },
'uLADjEzNvUAR3JinsKFtvK.png': { uri: '/images/uLADjEzNvUAR3JinsKFtvK.png' },
'nbSUCEj5ndCvV2obNrnJ31.png': { uri: '/images/nbSUCEj5ndCvV2obNrnJ31.png' },
'oxmpGEucmMrkj6MPuZThtv.png': { uri: '/images/oxmpGEucmMrkj6MPuZThtv.png' },
'dLzENAZZ49Mw7gDU1p4N8u.png': { uri: '/images/dLzENAZZ49Mw7gDU1p4N8u.png' },
'5U3bScjrFn5xef5GvPNzPW.png': { uri: '/images/5U3bScjrFn5xef5GvPNzPW.png' },
'7MisQYJNs9KhioBrfvAm1o.png': { uri: '/images/7MisQYJNs9KhioBrfvAm1o.png' },
'tqcULMxqkDRYjpmjEqDw83.png': { uri: '/images/tqcULMxqkDRYjpmjEqDw83.png' },
'kZZVDooHHnfe2DKXbsTtWP.png': { uri: '/images/kZZVDooHHnfe2DKXbsTtWP.png' },
'sPzrEDnWGomLGpiz8rTpDD.png': { uri: '/images/sPzrEDnWGomLGpiz8rTpDD.png' },
'4bnhmspW8RepZHEcNMySz6.jpg': { uri: '/images/4bnhmspW8RepZHEcNMySz6.jpg' },
'wjrZhf6bdwu5U3M7gedvVW.jpg': { uri: '/images/wjrZhf6bdwu5U3M7gedvVW.jpg' },
'ww4QRQdiU3AcniAdBjG4SZ.jpg': { uri: '/images/ww4QRQdiU3AcniAdBjG4SZ.jpg' },
'drkKWKccPXRNHY1LAe42a3.jpg': { uri: '/images/drkKWKccPXRNHY1LAe42a3.jpg' },
'iy9kAteRcnUgqHmTRwS3HM.jpg': { uri: '/images/iy9kAteRcnUgqHmTRwS3HM.jpg' },
'3vqF5oif4TM9SYxTLY9u96.jpg': { uri: '/images/3vqF5oif4TM9SYxTLY9u96.jpg' },
'r7UUAM3uDmNfkFK69D1Fzg.jpg': { uri: '/images/r7UUAM3uDmNfkFK69D1Fzg.jpg' }
}

export default imageStaticSourcesByFileName
